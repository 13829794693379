<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import coachs from "@/coachs.json";

/**
 * User list component
 */
export default {
  components: { Layout, PageHeader },
  page: {
    title: "Planning Programs",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Planning",
      items: [
        {
          text: "Planning",
        },
        {
          text: "Planning",
          active: true,
        },
      ],
      planningList: [],
      programsToValidate: [],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      //return this.userList.length;
      return 1;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    firebase.auth().onAuthStateChanged((currentUser) => {
      if (currentUser) {
        this.getPlanning();
        this.getProgramsToValidate();
      }
    });
  },
  methods: {
    getColor(days) {
      if (days < 0) return "table-danger";
      else if (days >= 0 && days <= 7) return "table-warning";
      else return "table-success";
    },
    dateFromTimestamp(timestamp) {
      var date = new Date(timestamp);
      return (
        date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear()
      );
    },
    daysBetweenTimestamps(date) {
      var now = new Date().getTime();
      // The number of milliseconds in one day
      const ONE_DAY = 1000 * 60 * 60 * 24;

      // Calculate the difference in milliseconds
      const differenceMs = date - now;
      // Convert back to days and return
      var diffJours = Math.round(differenceMs / ONE_DAY);
      return diffJours;
    },
    unpublishprogram(idProgram) {
      var db = firebase.firestore();
      db.collection("premiumprograms").doc(idProgram).update({ status: false });
    },
    publishprogram(idProgram) {
      var db = firebase.firestore();
      db.collection("premiumprograms").doc(idProgram).update({ status: true });
    },
    async getProgramsToValidate() {
      this.loadingValidate = true;
      var db = firebase.firestore();
      var requete = db
        .collection("premiumprograms")
        .orderBy("range.start", "asc");

      requete = requete.where("status", "==", false);

      if (!JSON.parse(this.isAdmin)) {
        await firebase
          .firestore()
          .collection("users")
          .doc(firebase.auth().currentUser.uid)
          .get()
          .then((coach) => {
            requete = requete.where("coach", "==", coach.ref);
          });
      }

      requete.onSnapshot(async (programs) => {
        this.programsToValidate = [];
        programs.forEach(async (program) => {
          var data = program.data();
          var user = data.user;
          var coach = data.coach;
          var userData = await user.get();
          var coachData = await coach.get();
          var userId = user.id;
          console.log("user data:" + userId);
          this.programsToValidate.push({
            id: program.id,
            data: program.data(),
            user: userData.data(),
            userId: userId,
            coach: coachData.data()
          });
          this.loadingValidate = false;
        });
      });
    },
    getPlanning() {
      this.loadingPlanning = true;
      var db = firebase.firestore();
      var requete = db
        .collection("users")
        .orderBy("next_program_premium", "asc");
      requete = requete.where("role", "array-contains", "premium");

      if (!JSON.parse(this.isAdmin))
        requete = requete.where("coach_programs", "==", firebase.auth().currentUser.uid);

      requete.onSnapshot((users) => {
        this.planningList = [];
        users.forEach(async (user) => {
          this.planningList.push({
            id: user.id,
            data: user.data(),
            coach: user.data().coach != null ? coachs[user.data().coach] : null
          });
          this.loadingPlanning = false;
        });
      });
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h2 class="my-3">Programs to validate</h2>

            <table class="table mb-4">
              <thead>
                <tr>
                  <th scope="col" width="50px">#</th>
                  <th scope="col">User</th>
                  <th scope="col">Date</th>
                  <th scope="col">Coach</th>
                  <th scope="col" width="200px" v-if="JSON.parse(isAdmin)"></th>
                  <th scope="col" width="200px"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="program in programsToValidate"
                  :key="program.id + 'program'"
                >
                  <td class="align-middle">
                    <img
                      v-if="program.user.photo_profil"
                      :src="program.user.photo_profil"
                      alt
                      class="avatar-xs rounded-circle me-2"
                    />
                    <div
                      v-if="!program.user.photo_profil"
                      class="avatar-xs d-inline-block me-2"
                    >
                      <div
                        class="
                          avatar-title
                          bg-soft-primary
                          rounded-circle
                          text-primary
                        "
                      >
                        <i class="mdi mdi-account-circle m-0"></i>
                      </div>
                    </div>
                  </td>
                  <td class="align-middle">
                    <router-link
                      :to="{ name: 'Profile', params: { id: program.userId } }"
                    >
                      {{ program.user.first_name }}
                      {{ program.user.last_name }}
                    </router-link>
                  </td>
                  <td class="align-middle">{{ program.data.range.start }} => {{ program.data.range.end }}</td>
                  <td>{{ program.coach.first_name }} {{ program.coach.last_name }}</td>
                  <td class="align-middle" v-if="JSON.parse(isAdmin)">
                    <button
                      v-if="program.data.status"
                      type="button"
                      class="btn btn-sm btn-outline-success"
                      @click="unpublishprogram(program.id)"
                    >
                      <i class="fas fa-check"></i> Published
                    </button>
                    <button
                      v-if="!program.data.status"
                      type="button"
                      class="btn btn-sm btn-outline-danger"
                      @click="publishprogram(program.id)"
                    >
                      <i class="fas fa-check"></i> Not Publish
                    </button>
                  </td>
                  <td class="align-middle">
                    <a
                          class="btn btn-primary"
                          :href="`/premiumprogram/edit/${program.id}`"
                          role="button"
                          target="_blank"
                          ><i class="fas fa-edit"></i
                        ></a>
                  </td>
                </tr>
              </tbody>
            </table>
            <hr />
            <h2 class="my-3">Next programs</h2>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" width="50px">#</th>
                  <th scope="col">User</th>
                  <th scope="col">Date</th>
                  <th scope="col">Coach</th>
                  <th scope="col" width="200px"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="user in planningList"
                  :key="user.id + 'program'"
                  :class="
                    getColor(
                      daysBetweenTimestamps(user.data.next_program_premium)
                    )
                  "
                >
                  <td class="align-middle">
                    <img
                      v-if="user.data.photo_profil"
                      :src="user.data.photo_profil"
                      alt
                      class="avatar-xs rounded-circle me-2"
                    />
                    <div
                      v-if="!user.data.photo_profil"
                      class="avatar-xs d-inline-block me-2"
                    >
                      <div
                        class="
                          avatar-title
                          bg-soft-primary
                          rounded-circle
                          text-primary
                        "
                      >
                        <i class="mdi mdi-account-circle m-0"></i>
                      </div>
                    </div>
                  </td>
                  <td class="align-middle">
                    <router-link
                      :to="{ name: 'Profile', params: { id: user.id } }"
                    >
                      {{ user.data.first_name }}
                      {{ user.data.last_name }}
                    </router-link>
                  </td>
                  <td>
                    <strong
                      >{{
                        daysBetweenTimestamps(user.data.next_program_premium)
                      }}
                      days</strong
                    ><br />
                    <small
                      >({{
                        dateFromTimestamp(user.data.next_program_premium)
                      }})</small
                    >
                  </td>
                  <td class="align-middle">{{ user.coach }}</td>
                  <td class="align-middle">
                    <router-link
                      :to="{
                        name: 'Premiumprogramnew',
                        params: { iduser: user.id, idcoach: user.data.coach },
                      }"
                      target="_blank"
                    >
                      <div class="d-grid gap-2">
                        <button type="button" class="btn btn-light">
                          + New program
                        </button>
                      </div></router-link
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
